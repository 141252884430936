<template>
  <div id="banner-details">
    <div id="banner-detail">
      <div class="banner-title">{{Title}}</div>
      <div style="float: right;color:#999">
        发布时间 :<NTime > {{Time}}</NTime>
      </div>
      <!-- <div v-if="ImgSrc!=''&&ImgSrc!=null" style="position: relative;top: 28px;">
        <img :src="ImgSrc" alt="" style="width: 100%;">
      </div> -->
      <div v-html="Datas" style="margin-top:30px" class="html-container"></div>
      <PageLoading :loading="loading" />
    </div>
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h } from "vue";
import { useMessage, NTime } from "naive-ui";

import { resStatusEnum } from "@/enumerators/http.js";
import { GetBannerDetail } from "@/api/banner.js";  
import { toRaw } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router';
import PageLoading from "@/components/PageLoading/index.vue";

const message = useMessage();
const { SUCCESS } = resStatusEnum;
const loading = ref(false);
const isOne = ref(true);  // 数据是否初始化
const route = useRoute();
const router = useRouter();
const ID = route.query.id;


 // 返回事件
const handleBack = () => {
  router.push('/banner/index/index');
};

const Datas = ref(null)
const Title = ref(null)
const Time = ref(null)
const ImgSrc = ref(null)
// 数据初始化
function updateList(data){
  loading.value = true;
  // 请求列表数据
  GetBannerDetail({"Banner[id]":ID})
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Datas.value = res.data.introduce
        Title.value = res.data.title
        Time.value = (Number(res.data.create_time) || 0) * 1000
        isOne.value = false;
        ImgSrc.value = res.data.image_url
      }
    })
    .catch((reject) => {});
};
updateList();

</script>

<style scoped>
#banner-details {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
#banner-detail{
  width: 60%;
  background: #fff;
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}
.banner-title{
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}
#banner-details div img{
  max-width:1200px
}
iframe{
  height: 100%;
}

.html-container :deep(img) {
  max-width: 100%;
}
</style>